import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AlbumDetail, ButtonAdd, Caratula, Songsli } from './albumDetails';
import { RiPlayListAddFill } from "react-icons/ri";
import { StyledSidebar } from './menuLateralStyles';
import LateralMenu from './menuLateral';
const AlbumDetailPage=()=> {
  const { id } = useParams();
  const [album, setAlbum] = useState({});
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
  const fetchAlbum=async()=> {
      try {
        const response = await fetch(`https://music-api-0avu.onrender.com/api/albums/${id}`);
        const data = await response.json();
      
        setAlbum(data[0]);
        setLoading(false);
        console.log(data);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchAlbum();
  }, [id]);
  const handleAddToPlaylist = async (song) => {
    try {
      const loginToken = localStorage.getItem('logintoken');
  
      const response = await fetch(`https://music-api-0avu.onrender.com/playlist/UserPlaylists`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${loginToken}`,
        },
      });
  
      if (!response.ok) {
        console.error('Error fetching playlists');
        window.alert('Registrate y/o Inicia  Sesion para Guardar tu Playlist ');
        return;
      }
  
      const playlists = await response.json();
      const playlistNames = playlists.map((playlist) => playlist.nombre);
  
      if (playlistNames.length === 0) {
        const newPlaylistName = window.prompt('No hay playlist existente . Nombre de nueva playlist :');
      if (newPlaylistName) {
      try {
            const newResponse = await fetch('https://music-api-0avu.onrender.com/playList/newPlaylist', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${loginToken}`,
              },
              body: JSON.stringify({
                songId: [song.id],
                albumId: album.id,
                nombre: newPlaylistName,
                album: album,
              }),
            });
  
            if (newResponse.ok) {
              console.log('tema añadido a playlist creada');
            } else {
              console.error('Error al añadir cancion');
            }
          } catch (error) {
            console.error('Error al crear playlist:', error);
          }
        } else {
          console.error('no existe ese nombre de playlist ');
          return;
        }
      } else {
        const selectedPlaylist = window.prompt('Selecciona una playlist', playlistNames.join(', '));
        if (selectedPlaylist && playlistNames.includes(selectedPlaylist)) {
          try {
            const responseAdd = await fetch('https://music-api-0avu.onrender.com/playList/addToPlaylist', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${loginToken}`,
              },
              body: JSON.stringify({
                songId: [song.id],
                albumId: album.id,
                titulo: album.titulo,
                caratula: album.caratula,
                song: song,
                album: song.album,
                playlistName: selectedPlaylist,
              }),
            });
  
            if (responseAdd.ok) {
              console.log('tema añadido a playlist');
            } else {
              console.error('error a añadir tema');
            }
          } catch (error) {
            console.error('error añadir tema en playlist', error);
          }
        } else {
          console.error('error en la playlist seleccionada ');
          
          return;
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  if (loading) {
    return <div>Loading...</div>;
  }
 




  return (
    

<div style={{ display: 'flex' }}>
      <StyledSidebar>
        <LateralMenu />
      </StyledSidebar>
  
      <div style={{ flex: '1' }}>
      <AlbumDetail>
      
      <Caratula src={album.caratula} alt="" />
      <h2>{album.titulo}</h2>
      <p>Artist: {album.artista}</p>
      <p>Year: {album.anio}</p>
      <h3>Songs:</h3>
      <ul>
        {album.songs.map((song) => (
          <Songsli key={song.id}>{song.title }
          {song.album }
            <ButtonAdd onClick={() => handleAddToPlaylist(song)}><RiPlayListAddFill/></ButtonAdd>
          </Songsli>
        ))}
      </ul>
    
        </AlbumDetail>
      
        </div>
  </div>
  );
  
}
export default AlbumDetailPage;