import React from 'react';
import { Link } from 'react-router-dom';
import { StyledSidebar, StyledMenu, StyledMenuItem } from './menuLateralStyles';

const LateralMenu = () => {
  return (
    <StyledSidebar className="sidebar">
      <StyledMenu className="menu">
        <StyledMenuItem>
          <Link to="/">Home</Link>
        </StyledMenuItem>
        <StyledMenuItem>
          <Link to="/UserPlaylist">Playlist</Link>
        </StyledMenuItem>
        <StyledMenuItem>
          <Link to="/register">Register</Link>
        </StyledMenuItem>
        <StyledMenuItem>
          <Link to="/login">Login</Link>
        </StyledMenuItem>
      </StyledMenu>
    </StyledSidebar>
  );
}

export default LateralMenu;