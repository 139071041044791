import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormContainer, RegisterContainer } from '../../common/styles';
import { StyledSidebar } from '../../common/menuLateralStyles';
import LateralMenu from '../../common/menuLateral';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const navigate=useNavigate();
  
  
  
  const handleLogin = async() => {
    try{
const response=await fetch('https://music-api-0avu.onrender.com/users/Login',{
method:'POST',
headers:{

  'Content-Type':'application/json',

},
body: JSON.stringify({
  email: email,
  contrasena: password,
}),
});
if (response.ok){
const responseData=await response.json();

const user=responseData.user;
const token = responseData.token;
localStorage.setItem('logintoken', token);
navigate('/');
console.log(responseData);

}else {
  const errorData = await response.json();
  console.error('Login error', errorData);
}
} catch (error) {
console.error('Error Login', error);
}
};

const handleLogout = async () => {
  try {
    localStorage.removeItem('logintoken');

    const response = await fetch('https://music-api-0avu.onrender.com/logout', {
      method: 'POST',
      credentials: 'include',
    });

    if (response.ok) {
      navigate('/');
      console.log('Logged out successfully');
    } else {
      const errorData = await response.json();
      console.error('Logout error', errorData);
    }
  } catch (error) {
    console.error('Error Logout', error);
  }
};

return (
  
 <div style={{ display: 'flex' }}>
    <StyledSidebar>
      <LateralMenu />
    </StyledSidebar>

    <div style={{ flex: '1' }}>
  <RegisterContainer>


    <h2>Login</h2>
    <FormContainer>
      <input
        type="text"
        placeholder="Email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        style={{ backgroundColor: 'white', color: 'black' }}
      />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          style={{ backgroundColor: 'white', color: 'black' }}
        />
        <button type="button" onClick={handleLogin}>
          Login
        </button>
        <button type="button" onClick={handleLogout}>
          Logout
        </button>
      </FormContainer>
    </RegisterContainer>
    </div>
    </div>
  );
};

export default Login;