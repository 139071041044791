import styled from "styled-components";

export const Caratula=styled.img`
width: 300px;
height: 300px;
cursor: pointer;
@media (max-width: 768px) {

    width: 150px;
    height: 150px;
  }
`


export const AlbumDetail=styled.div`
display: flex;
flex-direction: column;
background-color: black;
justify-items: center;
align-items: center;

color: white;
height: fit-content;
`
export const Songsli=styled.li`
list-style: none;


`
export const ButtonAdd=styled.button`
border: none;
background-color: black;
color: white;
cursor: pointer;
font-size: x-large;
padding: 20px;
`