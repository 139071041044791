import React from 'react';
import {Routes as ReactDomRutes, Route } from 'react-router-dom';
import HomePage from '../pages/Home/Home.jsx';
import AlbumDetailPage from '../common/albumDetails.jsx'
import Registration from '../pages/Register/Register.jsx';
import Login from '../pages/Login/Login.jsx';
import UserPlaylist from '../pages/Playlist/Playlists.jsx';


const Routes=()=> 
{
  return (
    <div>
    <ReactDomRutes>
      <Route path="/" exact element={<HomePage/>} />
      <Route path='/register'element={<Registration/>}/>
      <Route path="/album/:id" element={<AlbumDetailPage/>} />
      <Route path="/login" element={<Login/>}/>
      <Route path='/UserPlaylist'element={<UserPlaylist/>}/>
      </ReactDomRutes>
      </div>
  );
}

export default Routes;