import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick'; 
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 
import LateralMenu from '../../common/menuLateral';
import { StyledSidebar } from '../../common/menuLateralStyles';
import { Caratula } from '../../common/albumDetails';
import { Albumlist, ReccommededAlbums } from '../../common/styles';
import { Titles } from './Home';

const HomePage = () => {
  const [albums, setAlbums] = useState({ recommended: [], nonRecommended: [] });

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        const response = await fetch('https://music-api-0avu.onrender.com/api/albums');
        const data = await response.json();

        const recommendedAlbums = data.filter(album => album.recomendado);
        const nonRecommendedAlbums = data.filter(album => !album.recomendado);

        setAlbums({ recommended: recommendedAlbums, nonRecommended: nonRecommendedAlbums });
      } catch (error) {
        console.error('Error fetching albums:', error);
      }
    }

    fetchAlbums();
  }, []);


  return (<div style={{ display: 'flex' }}>
  <StyledSidebar>
    <LateralMenu />
  </StyledSidebar>
  <div style={{ flex: '1' }}>
 
    <ReccommededAlbums>
    <Titles>Recomendados</Titles>
      {albums.recommended.map(album => (
        <div key={album.id} className="carousel-item">
          <Link to={`/album/${album.id}`}>
            <Caratula src={album.caratula} alt="" />
          </Link>
          <h2>{album.titulo}</h2>
          <p>Artist: {album.artista}</p>
          <p>Year: {album.anio}</p>
        </div>
      ))}
    </ReccommededAlbums>
    <Albumlist>
      <h1>Albums</h1>
      {albums.nonRecommended.map(album => (
        <div key={album.id} className="album">
          <Link to={`/album/${album.id}`}>
            <Caratula src={album.caratula} alt="" />
          </Link>
          <h2>{album.titulo}</h2>
          <p>Artist: {album.artista}</p>
          <p>Year: {album.anio}</p>
        </div>
      ))}
    </Albumlist>
  </div>
</div>
);
};


   
export default HomePage;