import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import LoginTwoToneIcon from '@mui/icons-material/LoginTwoTone';
import PlaylistAddCircleOutlinedIcon from '@mui/icons-material/PlaylistAddCircleOutlined';

import { Link } from 'react-router-dom';

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);

  return (
    <Box sx={{ width: '100%' }}>
      <BottomNavigation 
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          backgroundColor: 'rgb(0, 0, 0)',
          display: 'none', 
          '@media (max-width: 700px)': {
            display: 'block', 
          },
        }}
      >
        <BottomNavigationAction label="Home" icon={<RestoreIcon />} LinkComponent={Link} to="/" sx={buttonStyle} />
        <BottomNavigationAction label="Register" icon={<AppRegistrationRoundedIcon />}LinkComponent={Link} to="/register" sx={buttonStyle} />
        <BottomNavigationAction label="Login" icon={<LoginTwoToneIcon />}LinkComponent={Link} to="/login" sx={buttonStyle} />
        <BottomNavigationAction label="Playist" icon={<PlaylistAddCircleOutlinedIcon />}LinkComponent={Link} to="/UserPlaylist" sx={buttonStyle} />
      </BottomNavigation>
    </Box>
  );
}

const buttonStyle = {
  color: 'white',
  width: '25%',
  '&:hover': {
    backgroundColor: 'rgb(8, 8, 8)',
  },
};