import styled from 'styled-components';

export const StyledSidebar = styled.aside`
  width: 250px;
  background-color: black;
  color: white;
  height: auto;
  @media (max-width: 700px) {
    display: none;
  }
`;


export const StyledMenu = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  
  gap: 100px;
`;

export const StyledMenuItem = styled.li`
  padding: 10px;


  a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;