import React from 'react';
import { Busqueda, ModalOverlay } from './Nabvar';

const BusquedaModal = ({ searchResults, onClose }) => {
  return (
    <ModalOverlay>
      <Busqueda>
        <button onClick={onClose}>Cerrar</button>
        <h2>Resultados de búsqueda:</h2>
        <img src={searchResults.caratula} alt="Carátula del álbum" style={{ maxWidth: '100px' }} /> <br />
        <strong>Título del álbum:</strong> {searchResults.titulo} <br />
        <strong>Artista:</strong> {searchResults && searchResults.artista} <br />
        <strong>Año:</strong> {searchResults && searchResults.anio} <br />
        <strong>Canción:</strong> {searchResults.canciones[0].title} <br />
      </Busqueda>
    </ModalOverlay>
  );
};

export default BusquedaModal;
 