import styled from "styled-components";

export const Albumlist=styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: black;
color:white;
flex-wrap: wrap;
  gap: 20px;

  .album {
    flex: 0 0 calc(33.33% - 20px);
    box-sizing: border-box;

    @media (max-width: 768px) {
      flex: 0 0 calc(50% - 20px); 
    }
  }
width: 100%;
height: auto;
box-sizing: border-box;
`
export const AlbumRecommend=styled.div`

display: flex;
flex-direction: row;
padding: 5px;


`


export const ReccommededAlbums = styled.div`
  padding: 5px;
  background-color: black;
  display: flex;
  color: white;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

  .carousel-item {
    flex: 0 0 calc(33.33% - 20px);
    box-sizing: border-box;

    @media (max-width: 1300px) {
      flex: 0 0 calc(50% - 20px);
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: 768px) {
      flex: 0 0 calc(100% - 20px);
      margin: 10px 0;
      width: 100%;
    }
  }
`;

  
export const RegisterContainer=styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: black;
color: white;
height: 100vh;
`
export const FormContainer=styled.form`
display: flex;
flex-direction: column;
padding: 10px;
gap: 50px;

`