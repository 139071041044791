import React, { useState } from 'react';
import { NavbarContainer, LogoStyle, LogoContainer, TitleContainer, SearchContainer, SearchInput, Busqueda } from './Nabvar';
import logo from './images.png';
import BusquedaModal from './BusquedaModal';

const Navbar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const handleSearch = async () => {
    try {
      const response = await fetch(`https://music-api-0avu.onrender.com/Search?searchTerm=${searchTerm}`);
      const data = await response.json();
      console.log(data);
  
      const filteredSongs = data.flatMap(album => album.songs.filter(song => song.title.toLowerCase() === searchTerm.toLowerCase()));
  
      if (filteredSongs.length > 0) {
        const matchingSong = filteredSongs[0];
        const matchingAlbum = data.find(album => album.songs.some(song => song.title.toLowerCase() === searchTerm.toLowerCase()));
        console.log(matchingAlbum);
  
        if (matchingAlbum) {
          console.log('Artista:', matchingAlbum.artista);
          console.log('Año:', matchingAlbum.anio);
          setMenuOpen(true);
          setSearchResults({
            caratula: matchingAlbum.caratula,
            titulo: matchingAlbum.titulo,
            artista: matchingAlbum.artista,
            anio: matchingAlbum.anio,
            canciones: [matchingSong],
            
          });
          
        }
      
      } else {
        setSearchResults(null);
      }
    } catch (error) {
      console.error('Error en la búsqueda:', error);
    }
  };
    
  return (
    <div>
      <NavbarContainer>
        <LogoContainer>
          <a href="/"><img src={logo} alt='Fridal' style={LogoStyle} /></a>
        </LogoContainer>
        <TitleContainer>
          <h1>Fridal</h1>
        </TitleContainer>
        <SearchContainer>
          <SearchInput
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button onClick={handleSearch}>Buscar</button>
        </SearchContainer>
      </NavbarContainer>
      
    
      
      {searchResults && (
        <div>
          {menuOpen && (
            <BusquedaModal searchResults={searchResults} onClose={() => setMenuOpen(false)} />
          )}
        </div>
      )}
    </div>
  );
};
       
        

export default Navbar;