
import React, { useEffect, useState } from "react";
import { StyledSidebar } from "../../common/menuLateralStyles";
import LateralMenu from "../../common/menuLateral";
import { RegisterContainer } from "../../common/styles";

const UserPlaylist = () => {
  const [playlists, setPlaylists] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPlaylist = async () => {
    try {
      const loginToken = localStorage.getItem("logintoken");
      const response = await fetch(`https://music-api-0avu.onrender.com/playlist/UserPlaylists`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${loginToken}`,
        },
      });

      if (!response.ok) {
        console.error("Failed to fetch user playlists");
        return;
      }

      const playlistsData = await response.json();
      setPlaylists(playlistsData);
      console.log("playlists data:", playlistsData);
      setLoading(false);
    } catch (error) {
      console.error("Error user playlists:", error);
    }
  };

  useEffect(() => {
    fetchPlaylist();
  }, []);

  const handleDeletePlaylist = async (playlistId) => {
    try {
      const loginToken = localStorage.getItem("logintoken");
      const response = await fetch(`https://music-api-0avu.onrender.com/playlist/${playlistId}`, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${loginToken}`,
        },
      });

      if (response.ok) {
       
        fetchPlaylist();
      } else {
        console.error("Failed to delete playlist");
      }
    } catch (error) {
      console.error("Error deleting playlist:", error);
    }
  };

  return (
<div style={{ display: 'flex' }}>
      <StyledSidebar>
        <LateralMenu />
      </StyledSidebar>
  
      <div style={{ flex: '1' }}>
    <RegisterContainer>
      
  
      <h2>Playlists</h2>
      {playlists.length === 0 ? (
      <p>No hay ninguna playlist creada.</p>
    ) : (
      <ul>
        {playlists.map((playlist) => (
          <li key={playlist.id}>
            <h3>{playlist.nombre}</h3>
            
            <button onClick={() => handleDeletePlaylist(playlist.id)}>Delete Playlist</button>
            <ul>
              {playlist.songs.map((song) => (
                <li key={song.id}>{song.title}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    )}</RegisterContainer>
     </div>
    </div>
  );
};

export default UserPlaylist;





