import styled from 'styled-components'


export const NavbarContainer = styled.nav`
  background-color:black;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  
  height: 100px;
  margin-top: 0;
  `

export const LogoStyle = {
    width: '100px',
    height: '100px',
    
  };

  export const LogoContainer=styled.div`

    width: '100px';
    height: '100px';

  `
  export const TitleContainer=styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  `

  export const SearchContainer=styled.div`
  display: 'flex';
  align-items: center;
  margin-left: auto;
  padding: 10px;

  `
  export const SearchInput=styled.input`
  display: flex;
  
  
  `
export const Busqueda=styled.div`
background-color: black;
color: white;
display: flex;
margin-left: auto;
margin-right: 0; 
justify-content: flex-start;
flex-direction: column;

`
export const ModalOverlay=styled.div`
 position: fixed;
top: 10%;
right: 0%;
  width: 50%;
  height: 500px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid white;
`
