import styled from "styled-components";

export  const HomeContainer=styled.div`
  display: flex;
  

`
export const Albumcontainer=styled.div`

display:  flex;

`

export const Titles=styled.h1`
display: flex;
width: 100%;
flex-wrap: wrap;
justify-content: center;
align-items: center;
background-color: black;
color: white;


`