import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormContainer, RegisterContainer } from '../../common/styles';
import { StyledSidebar } from '../../common/menuLateralStyles';
import LateralMenu from '../../common/menuLateral';

const Registration= () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const handleRegistration =async () => {
    
 try{
const response=await fetch('https://music-api-0avu.onrender.com/users/NewUser',{
method:'POST',
headers:{
    'Content-Type':'application/json',
},
body:JSON.stringify({
    email:email,
nombre:username,
contrasena: password,

}),
})

if (response.ok){
navigate('/');

}else{
const errorData=await response.json();
console.error('Registacion error', errorData);
}
}catch(error){

    console.error('Error Register',error);
}

 }
  
  return (
    <div style={{ display: 'flex' }}>
    <StyledSidebar>
      <LateralMenu />
    </StyledSidebar>

    <div style={{ flex: '1' }}>
  <RegisterContainer>
      <h2>Register</h2>
      <FormContainer>
        <input 
          type="text"
          placeholder="Username"
          value={username}
          onChange={e => setUsername(e.target.value)}
          style={{ backgroundColor: 'white', color: 'black' }}
        />
        <input
  type="text"
  placeholder="Email" 
  value={email}
  onChange={e => setEmail(e.target.value)}
/>
        <input
     
          type="password"
          placeholder="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          style={{ backgroundColor: 'white', color: 'black' }}
        />
        <button type="button" onClick={handleRegistration}>
          Register
        </button>
      </FormContainer>
    </RegisterContainer>
    </div>
    </div>
  );
};

export default Registration;



