import React from "react";
import './App.css';
import SimpleBottomNavigation from './ButtomNavigation/buttomMenu';
import Navbar from "./navbar/Navbar";

import { BrowserRouter } from 'react-router-dom'; 
import HomePage from "./pages/Home/Home";
import Routes from "./Routes/routes";
import AlbumDetailPage from "./common/albumDetails";


function App() {
 

  return (
    
      <BrowserRouter>
        
        <Navbar />
<Routes/>
               
  <SimpleBottomNavigation />
        
      </BrowserRouter>
  
  );
}

export default App;